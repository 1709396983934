@charset 'utf-8';



// Custom Variables
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// Barlow Font Families
$barlow: 'Barlow', 'Helvetica Neue', Helvetica, Roboto, sans-serif;
$barlow-condensed: 'Barlow Condensed', 'Helvetica Neue', Helvetica, Roboto, sans-serif;

// Typography variables
$regular: 400;
$bold: 700;
$full-space-sm: 2rem;
$half-space-sm: ($full-space-sm / 2);
$full-space-md: 3rem;
$half-space-md: ($full-space-md / 2);
$full-space-lg: 4rem;
$half-space-lg: ($full-space-lg / 2);
$header-height-sm: 60px;
$header-height-md: 76px;
$nav-height-lg: 52px;
$header-height-lg: $nav-height-lg + 60px;
$sticky-header-height-lg: $nav-height-lg + 30px;
$body-max-width: 2560px;



// Foundation imports/includes
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

@import 'settings';
@import 'foundation';
@import 'motion-ui';

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-switch;
@include foundation-table;
// Basic components
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
@include foundation-pagination;
@include foundation-tooltip;

// Containers
@include foundation-accordion;
@include foundation-media-object;
@include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;
@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
@include foundation-title-bar;
@include foundation-top-bar;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;

// Motion UI
@include motion-ui-transitions;
@include motion-ui-animations;



// Custom SCSS imports/includes
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

@import 'global/typography';
@import 'global/icons';
@import 'global/buttons';
@import 'global/images';
@import 'global/navigation';
@import 'global/layout';
@import 'global/modules';
@import 'global/helpers';
@import 'global/print';