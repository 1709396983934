// Layout / Sections / Containers
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// Padded section
.padded-section {
    position: relative;
    overflow: hidden;
    padding: $full-space-sm 0;

    // "medium" size only
    @include breakpoint(medium only) {
        padding-top: $full-space-md;
        padding-bottom: $full-space-md;
    }

    // "large" size +
    @include breakpoint(large) {
        padding-top: $full-space-lg;
        padding-bottom: $full-space-lg;
    }
}

// Padded section with extra padding
.padded-section--large {
    padding-top: $full-space-sm;
    padding-right: 0;
    padding-bottom: $full-space-sm;
    padding-left: 0;

    // "medium" size only
    @include breakpoint(medium only) {
        padding-top: ($full-space-md * 2);
        padding-bottom: ($full-space-md * 2);
    }

    // "large" size +
    @include breakpoint(large) {
        padding-top: ($full-space-lg * 2);
        padding-bottom: ($full-space-lg * 2);
    }
}

// Padded section with less padding
.padded-section--small {
    padding: 1rem 0;
}

// Narrow container
.narrow-container {
    display: block; 
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 2rem;

    // 480px - medium
    @media screen and (min-width: 480px) and (max-width:767px) {
        padding-left: $full-space-sm * 2;
        padding-right: $full-space-sm * 2;
    }

    // "medium" size only
    @include breakpoint(medium only) {
        padding-left: $full-space-md * 2;
        padding-right: $full-space-md * 2;
    }

    // "large" size +
    @include breakpoint(large) {
        padding-left: $full-space-lg * 2;
        padding-right: $full-space-lg * 2;
    }
}

// Grid container
.grid-container {
    max-width: 1680px;
    padding: 0 1rem;

    // 480px - medium
    @media screen and (min-width: 480px) and (max-width:767px) {
        padding-left: $full-space-sm;
        padding-right: $full-space-sm;
    }

    // "medium" size only
    @include breakpoint(medium only) {
        padding-left: $full-space-md;
        padding-right: $full-space-md;
    }

    // "large" size +
    @include breakpoint(large) {
        padding-left: $full-space-lg;
        padding-right: $full-space-lg;
    }
}

// Grid container with no max-width
.grid-container--no-max {
    max-width: 100%;
}

// Footer
.footer {
    text-align: center;

    // "medium" +
    @include breakpoint(medium) {
        text-align: left;
    }
}



// Sidebar
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// Sidebar
.sidebar {

    // "large" size +
    @include breakpoint(large) {
        padding-left: 1rem;
    }
}