// Skip To
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// Skip to link
.skip-to, 
.skip-to:visited {
    position: fixed !important;
    padding: 0.5rem 1rem !important;
    top: 0 !important;
    left: auto !important;
    background-color: $primary-color !important;
    border: 2px solid $white !important;
    border-radius: $button-radius !important;
    color: $white !important;
    z-index: 9999 !important;

    // Focus state
	&:focus {
		transition: all 0s ease 0s;
		display: inline-block;
	}
}



// Header
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// Header
.header {
    position: relative;
    z-index: 99;
    width: 100%;
    max-width: $body-max-width;
    -webkit-box-shadow: $shadow;
    -moz-box-shadow:    $shadow;
    box-shadow:         $shadow;
}

// Header
.header__top {
    position: relative;
    background-color: $white;
    color: $primary-color;
    height: $header-height-sm;

    // "medium" size only
    @include breakpoint(medium only) {
        height: $header-height-md;
    }

    // "large" size +
    @include breakpoint(large) {
        height: $header-height-lg;
    }
}


// Header Logo
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// Header logo
.header__logo {
    @extend %link-reset;
    position: absolute;
    top: 12px;
    left: auto;
    z-index: 999;
    display: block;
    width: auto;
    height: $header-height-sm - 24px;
    transition: all 0.3s ease;

    // "medium" size only
    @include breakpoint(medium only) {
        top: 15px;
        height: $header-height-md - 30px;
    }

    // "large" size +
    @include breakpoint(large) {
        top: 25px;
        height: $header-height-lg - 50px;
    }

    // img
    img {
        width: auto;
        height: 100%;
    }
}



// Main Nav
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// Header main <nav>
.header__menu__main {
    border-top: 1px solid $black-15;

    // "large" size +
    @include breakpoint(large) {
        border-top: 0;
    }

    // Top level menu <ul>
    > .menu {
        opacity: 0; // covers js issue
    }

    // Header main <ul> (both)
    .menu {
        list-style: none;
        padding: 0;
        margin: 0;

        // li
        li {

            // a
            a {
                border-bottom: 1px solid $black-15;

                // Hover state
                &:hover, &:active, &:focus {
                    background-color: $hover-darken-less;
                }
            }

            // Nested <ul>
            ul, .nested {

                // "large" size +
                @include breakpoint(large) {
                    background-color: $light-gray;
                    padding-top: 8px;
                    padding-bottom: 8px;
                    -webkit-box-shadow: $shadow;
                    -moz-box-shadow:    $shadow;
                    box-shadow:         $shadow;
                }
            }
        }
    }



    // Mobile Nav Only (Accordion)
    .accordion-menu {
        opacity: 1.0; // covers js issue

        // Nested <ul>
        .is-accordion-submenu, 
        .nested.is-accordion-submenu {
            padding: 0;
            margin: 0;

            // 2nd level a
            li a {
                padding-left: 2rem;
            }

            // 3rd level a
            li li a {
                padding-left: 3rem;
            }

            // 4th level a
            li li li a {
                padding-left: 4rem;
            }
        }
    }



    // Desktop Nav Only (Dropdown)
    .dropdown {
        opacity: 1.0; // covers js issue

        // "large" size +
        @include breakpoint(large) {
            justify-content: flex-end;
            border-top: 0;
        }

        // All <li>'s
        li {

            // "large" size +
            @include breakpoint(large) {
                border-bottom: 0;
            }

            // All <a>'s
            a {

                // "large" size +
                @include breakpoint(large) {
                    border-bottom: 0;
                }
            }
        }



        // Top Level <a>
        > li {

            // "large" size +
            @include breakpoint(large) {
                margin-right: 0.5rem;
            }

            // Link
            > a {

                // "large" size +
                @include breakpoint(large) {
                    padding: 15px 1rem 0;
                    min-height: $nav-height-lg;
                    border-radius: $button-radius;
                }

                // Hover state
                &:hover, &:active, &:focus {

                    // "large" size +
                    @include breakpoint(large) {
                        background-color: $light-gray;
                    }            
                }

                // Covers drop shadow
                &:before {

                    // "large" size +
                    @include breakpoint(large) {
                        transition: all 0.3s ease;
                    }
                }
            }
        }



        // Adds drop shadow to active top level <a>
        > li.is-active > a {

            // "large" size +
            @include breakpoint(large) {
                -webkit-box-shadow: $shadow;
                -moz-box-shadow:    $shadow;
                box-shadow:         $shadow;
            }

            // Covers drop shadow
            &:before {

                // "large" size +
                @include breakpoint(large) {
                    content: "";
                    width: 100%;
                    height: 12px;
                    display: block;
                    position: absolute;
                    bottom: -6px;
                    left: 0;
                    z-index: 99999;
                    background: $light-gray;
                }
            }
        }
    }

    //  Top level <a> with dropdown
    .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a {

        // "large" size +
        @include breakpoint(large) {
            padding-right: 2rem;
            position: relative;
        }

        // Dropdown arrow
        &:after {

            // "large" size +
            @include breakpoint(large) {
                right: 0.85rem;
            }
        }
    }



    // CTA link (both Mobile and Desktop)
    .accordion-menu .header__nav__cta, 
    .dropdown .header__nav__cta {
        margin-right: 0;

        // Link
        > a {
            border-radius: 100px;
            text-transform: uppercase;
            letter-spacing: 0.5px;
            text-align: center;
            border: 2px solid $black-15;
            margin-top: 2rem;
            
            // "large" size +
            @include breakpoint(large) {
                margin-top: 0;
                margin-left: 1rem;
                padding-top: 13px;
                padding-left: 1.25rem;
                padding-right: 1.25rem;
            }

            // icon
            .icon-phone {
                margin-right: 0.5rem;
            }

            // Hover state
            &:hover, &:active, &:focus {
                background-color: $secondary-color;
                color: $white;
                
                // icon
                .icon-phone {
                    color: inherit;
                }
            }
        }
    }
}



// Menu Toggle Div
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// Toggle div that contains menu
.header__menu {
    display: none; // changed with js
    position: absolute;
    top: $header-height-sm;
    right: 0;
    width: 100%;
    max-width: 380px;
    padding: 1rem 1rem 0;

    // "medium" size +
    @include breakpoint(medium only) {
        top: $header-height-md;
    }

    // "large" size +
    @include breakpoint(large) {
        display: block; // always open
        top: 30px !important;
        max-width: 100%;
        padding-top: 0;
        padding-left: $full-space-lg;
        padding-right: $full-space-lg;
    }

    // Dropdown curtain
    &:after {

        // tablet and mobile only
        @include breakpoint(medium down) {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100vh;
            z-index: -1;
            background-color: $light-gray;
            -webkit-box-shadow: $shadow;
            -moz-box-shadow:    $shadow;
            box-shadow:         $shadow;
        }
    }

    // When open
    &.show {
        display: block;
    }
}



// Menu Toggle Button
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// Hamburger icon
.hamburger-icon {
	vertical-align: middle;
	display: inline-block;
	width: 25px;
	height: 3px;
	background-color: $primary-color;
	margin: 0 auto;
	padding: 0;
	position: relative;

	// top and bottom bars
	&:before, &:after {
		content: "";
		position: absolute;
		left: 0;
		display: block;
		width: 25px;
		height: 3px;
		background-color: $primary-color;
		margin: 0 auto;
		padding: 0;
	}

	// top bar
	&:before {
		top: -7px;
	}

	// bottom bar
	&:after {
		bottom: -7px;
	}
}

// X icon
.x-icon {
	vertical-align: middle;
	display: inline-block;
	width: 30px;
	height: 3px;
	position: relative;
	background-color: $primary-color;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);

	&:after {
		content: "";
		display: block;
		width: 30px;
		height: 3px;
		background-color: $primary-color;
		-webkit-transform: rotate(90deg);
		-ms-transform: rotate(90deg);
		transform: rotate(90deg);
	}
}

// Header menu toggle button
.header__menu-toggle-button {
    display: block;
    width: $header-height-sm;
    height: $header-height-sm;
    margin: 0;
    padding: 0;
    background: none;
    border-left: 1px solid $white-15;
    position: absolute;
    top: 0;
    right: 0;

    // "medium" size only
    @include breakpoint(medium only) {
        width: $header-height-md;
        height: $header-height-md;
    }

    // "large" size +
    @include breakpoint(large) {
        display: none;
    }

    // Hamburger icon
	.hamburger-icon {
        display: inline-block;
	}

	// Hamburger icon
	.x-icon {
		display: none;
    }
    
    // When open
    &[aria-expanded="true"] {

        // Hamburger icon
        .hamburger-icon {
            display: none;
        }

        // X icon
        .x-icon {
            display: inline-block;
        }
    }
}



// Contact Banner
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// Contact Banner
.contact-banner {
    padding: 0.25rem 0;
    text-align: center;
    
    // "medium" size +
    @include breakpoint(medium) {
        padding-top: 0;
        padding-bottom: 0;
        text-align: right;
        font-size: $small-font-size;
    }

    // ul
    ul {
        margin: 0 -0.5rem;
        padding: 0;
        list-style: none;

        // li
        li {
            display: inline-block;
            padding: 0.25rem 0.5rem;

            // "medium" size +
            @include breakpoint(medium) {
                padding-top: 0.5rem;
                padding-bottom: 0.5rem;
            }

            // All icons
            [class^="icon-"], [class*=" icon-"] {
                margin-right: 0.25rem;
            }

            // a
            a {
                @extend %link-reset;
            }
        }
    }
}



// Main content
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// <main>
main {

    // Remove focus outline
    &:focus {
        outline: none; // removes focus outline
    }
}



// Footer
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// Footer menu
.footer-menu {
    padding: 0;
    margin: 0 0 1rem;
    list-style: none;

    // li
    li {
        display: block;
        font-size: 1.25rem;
        padding: 0;
        margin: 0 0 1rem;

        // a
        a {
            display: inline-block;
            padding: 0;
            margin: 0;
        }
    }
}

// Footer bottom
.footer__bottom {

    // last paragraph
    p:last-child {
        margin-bottom: 0;
    }

    // separators ("|")
    span {
        display: inline-block;
        margin: 0 0.25rem;
    }
}