// A11y Helpers
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// Screen Reader Only
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

// Screen Reader Only (Visible on Focus)
.sr-only-focusable {
    &:active, &:focus {
        position: static;
        width: auto;
        height: auto;
        overflow: visible;
        clip: auto;
        white-space: normal;
    }
}



// Typography Helpers
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// Link style reset
%link-reset {
    font-weight: inherit;
    border-bottom: 0;

    // Hover state
	&:hover, &:focus, &:active {
		background-color: transparent;
	}
}

// Link reset class
.link-reset {
    @extend %link-reset;
}

// Text uppercase
.text-uppercase {
    text-transform: uppercase;
}

// Text center
.text-center {

    // all buttons
    .button {
        margin-right: 0.25rem;
        margin-left: 0.25rem;
    }
}



// Text Reverse
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// Reverses text color
.text-reverse {
    color: $white;

    // hr
    hr {
        background-color: $white-15;
    }

    // Dashed list
    .chip-list li:before {
        background-color: $white-15;
    }

    // Callouts
    .callout {
        background-color: transparent;
        color: inherit;
        border-color: $white-15;
    }

    // Tables
    table {
        th, td {
            border-color: $white-15;
        }
    }

    // Default Button
    .button.default {
        background-color: $white;
        color: $primary-color;

        // Hover state
        &:hover, &:focus, &:active {
            background-color: $light-gray;
        }
    }

    // Default Button (Hollow)
    .button.default.hollow {
        background-color: transparent;
        border-color: $white;
        color: $white;

        // Hover state
        &:hover, &:focus, &:active {
            background-color: $white;
            color: $primary-color;
        }
    }

    // All icons
    [class^="icon-"], [class*=" icon-"] {
        color: inherit;
    }
}



// Font Family Helpers
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// Barlow font family
.barlow {
    font-family: $barlow;
}

// Barlow Condensed font family
.barlow-condensed {
    font-family: $barlow-condensed;
}



// Background Helpers
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// Black bg
.black-bg {
    background-color: $black;
}

// Black 15 bg
.black-15-bg {
    background-color: $black-15;
}

// Black overlay bg
.black-overlay-bg {
    background-color: $black-overlay;
}

// Gray darkest bg
.gray-dark-bg {
    background-color: $dark-gray;
}

// Gray lightest bg
.gray-light-bg {
    background-image: url('../img/gray-light-texture.jpg');
    background-repeat: repeat;
    background-position: center center;
    background-size: 200px auto;
    background-color: $light-gray;
}

// White bg
.white-bg {
    background-color: $white;
}

// Primary bg
.primary-bg {
    background-color: $primary-color;
}

// Primary overlay bg
.primary-overlay-bg {
    background-color: $primary-overlay;
}

// Secondary bg
.secondary-bg {
    background-color: $secondary-color;
}

// Primary color overlay
.overlay {

    // Adds overlay
    &:after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: $primary-overlay;
    }

    // Fixes z-index issue
    > * {
        position: relative;
        z-index: 1;
    }
}

// Overlay with half intensity
.overlay--half:after {
    opacity: 0.75;
}

// Secondary color overlay
.overlay--secondary:after {
    background-color: $secondary-overlay;
}

// Black color overlay
.overlay--black:after {
    background-color: $black-overlay;
}

// White color overlay
.overlay--white:after {
    background-color: $white-overlay;
}

// Needed for photo bg
.photo-bg {
    background-size: cover;
    background-repeat: no-repeat;
    background-color: $dark-gray;
    background-position: center center;
}



// Margin / Spacing Helpers
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// Adds margin-top
.mar-top {
    margin-top: 2rem;
}

// Adds $half-space margin top
.mar-top-half {
    margin-top: $half-space-sm;

    // "medium" size only
    @include breakpoint(medium only) {
        margin-top: $half-space-md;
    }

    // "large" size +
    @include breakpoint(large) {
        margin-top: $half-space-lg;
    }
}

// Margin top 0
.mar-top-0 {
    margin-top: 0;
}

// Adds margin-bottom
.mar-bottom {
    margin-bottom: 2rem;
}

// Adds $half-space margin bottom
.mar-bottom-half {
    margin-bottom: $half-space-sm;

    // "medium" size only
    @include breakpoint(medium only) {
        margin-bottom: $half-space-md;
    }

    // "large" size +
    @include breakpoint(large) {
        margin-bottom: $half-space-lg;
    }
}

// Margin bottom 0
.mar-bottom-0 {
    margin-bottom: 0;
}

// Spacer
.spacer {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
    background: transparent;
    height: $full-space-sm;

    // "medium" size only
    @include breakpoint(medium only) {
        height: $full-space-md;
    }

    // "large" size +
    @include breakpoint(large) {
        height: $full-space-lg;
    }
}

// Spacer (Half)
.spacer--half {
    height: $half-space-sm;

    // "medium" size only
    @include breakpoint(medium only) {
        height: $half-space-md;
    }

    // "large" size +
    @include breakpoint(large) {
        height: $half-space-lg;
    }
}



// Block Align Classes
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// Block align left, align right, and center classes
.block--align-left, .block--align-right, .block--align-center {
    display: block;
    width: 48%;
    max-width: 100%;
    margin: 0.5rem auto 1rem;

    // // "medium" size only
    // @include breakpoint(medium only) {
    //     width: 40%;
    // }

    // // "large" size +
    // @include breakpoint(large) {
    //     width: 33.33%;
    // }
}

// Aligns block to the left
.block--align-left {
    float: left;
    margin-right: 1rem;

    // "medium" size only
    @include breakpoint(medium only) {
        margin-right: 1.5rem;
        margin-bottom: 1.5rem;
    }
    
    // "large" size +
    @include breakpoint(large) {
        margin-right: 2rem;
        margin-bottom: 2rem;
    }
}

// Aligns block to the right
.block--align-right {
    float: right;
    margin-left: 1rem;

    // "medium" size only
    @include breakpoint(medium only) {
        margin-left: 1.5rem;
        margin-bottom: 1.5rem;
    }
    
    // "large" size +
    @include breakpoint(large) {
        margin-left: 2rem;
        margin-bottom: 2rem;
    }
}

// Centers block
.block--align-center {
    text-align: center;
}



// Misc. Style Helpers
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// Opacity 75%
.opacity-75 {
    opacity: 0.75;
}

// Opacity 50%
.opacity-50 {
    opacity: 0.5;
}

// Opacity 20%
.opacity-20 {
    opacity: 0.2;
}

// Adds shadow
.shadow {
    border: 0;
    -webkit-box-shadow: $shadow;
    -moz-box-shadow:    $shadow;
    box-shadow:         $shadow;
    position: relative;
    z-index: 9;
}

// Removes border
.no-border {
    border: 0;
}