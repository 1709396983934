// Tables
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// table
table {

    // th cells
    th {
        font-weight: $bold;
    }

    // all cells
    th, td {
        border: 1px solid $black-15;
    }
}



// Google Map Embed
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// Contains map iframe
.map-embed {
    margin: 0 0 1rem;
    width: 100%;
    padding-top: 56.25%;
    position: relative;

    // iframe
    iframe {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 1px solid $black-15;
    }
}



// Callouts
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// Callout
.callout {
    padding: 1rem;

    // 480px - medium
    @media screen and (min-width: 480px) and (max-width:767px) {
        padding: $full-space-sm;
    }

    // "medium" size only
    @include breakpoint(medium only) {
        padding: $half-space-md;
    }

    // "large" size +
    @include breakpoint(large) {
        padding: $half-space-lg;
    }
}



// Social menu
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// Social menu <ul>
.social-menu {
    padding: 0;
    margin: 0 0 1rem;
    list-style: none;

    // li
    li {
        display: inline-block;
        margin: 0 0.25rem 0 0;
        text-align: center;

        // a
        a {
            display: block;
            height: 54px;
            width: 54px;
            border-radius: 50%;
            padding-top: 10px;
            border: 2px solid $white-15;

            // Hover state
            &:hover, &:active, &:focus {
                color: $white;
                border-color: $secondary-color;
                background-color: $secondary-color;
            }
        }
    }
}



// CTA Band
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// CTA band
.cta-band {
    margin: 0;
    padding: 0;
    list-style: none;

    // "medium" size only
    @include breakpoint(medium) {
        border-left: 1px solid $black-15;
    }

    // li
    li {
        float: left;
        width: 50%;
        line-height: $header-lineheight;

        // "medium" size only
        @include breakpoint(medium) {
            border-right: 1px solid $black-15;
            width: 25%;
        }
    }
}

// CTA band item
.cta-band__item {
    display: block;
    margin: 0;
    padding: 1rem;
    font-size: 1.15rem;
    border-bottom: 0;
    color: $primary-color;

    // 480px - medium
    @media screen and (min-width: 480px) and (max-width:767px) {
        padding: $half-space-sm;
    }

    // "medium" size only
    @include breakpoint(medium only) {
        padding: $half-space-md;
    }

    // "large" size +
    @include breakpoint(large) {
        padding: $half-space-lg;
    }

    // Hover state
	&:hover, &:focus, &:active {
        background-color: $hover-darken-less;
        color: $primary-color;
    }

    // Image
    .image {
        margin-right: 0;
        margin-bottom: 0.5rem;
    }
}

// CTA band item text
.cta-band__item__text {
    font-weight: $bold;
}



// Billboard Block
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// Full-width image block (used on home page)
.billboard {
	border-bottom: 1px solid $black-15;
    background-color: $dark-gray;

    // "large" size +
    @include breakpoint(large) {
		position: relative;
		border-bottom: 0;
		margin-bottom: 0;
		max-height: 80vh;
		overflow-y: hidden;
	}
}

// Image inside of full-width image block
.billboard__image {
	width: 100%;
	height: auto;
	display: block;
}

// Contains text inside of full-width image block
.billboard__text {
    padding: 1rem;

    // 480px - medium
    @media screen and (min-width: 480px) and (max-width:767px) {
        padding: $full-space-sm;
    }

    // "medium" size +
    @include breakpoint(medium) {
		padding: $half-space-md $full-space-md;
	}

    // "large" size +
    @include breakpoint(large) {
        position: absolute;
		top: 50%;
        left: $full-space-md;
        width: 50%;
        max-width: 745px;
        padding: $half-space-lg;
        transform: translateY(-50%);
	}
}

// Full-width image block with text on right
.billboard--right .billboard__text {

    // "large" size +
    @include breakpoint(large) {
        left: auto;
		right: $full-space-lg;
	}
}

// Full-width image block with text centered
.billboard--center .billboard__text {

    // "large" size +
    @include breakpoint(large) {
		left: 50%;
		transform: translate(-50%, -50%);
		text-align: center;
    }
}



// Featured (50/50) Image Block
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// Vertically centers 50/50 content
.content-50-50 {
    width: 100%;
    max-width: 100%;

	// "medium" size +
    @include breakpoint(medium) {
		display: flex;
		align-items: center;
		justify-content: center;
    }

    // Needed for bug fix
    div {
        width: 100%;
        max-width: 100%;
    }
}

// Content on the left (on desktop)
.content-50-50--left {

    // "medium" size only
    @include breakpoint(medium only) {
        padding-right: 1rem;
    }

    // "large" size +
    @include breakpoint(large) {
        padding-right: 2rem;
    }
}

// Content on the right (on desktop)
.content-50-50--right {

    // "medium" size only
    @include breakpoint(medium only) {
        padding-left: 1rem;
    }

    // "large" size +
    @include breakpoint(large) {
        padding-left: 2rem;
    }
}



// Staff Listing (Our Team)
// ---------------------------------------------
// ----------------------------------------
// -----------------------------------

// Staff listing
.staff-listing {
    
    // image
    .image {
        width: 85%;
        max-width: 360px;
        margin-bottom: 0.5rem;
    }
}